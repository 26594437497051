import { createApp } from 'vue'
import App from './App.vue'
import router from './router/router'
import songDB from './store/Song'

import './index.css'

const res = songDB.init()
res.then(()=> {
    createApp(App).use(router).mount('#app')
})
