<template>
    <div class="h-full">
        <song-pitch></song-pitch>
    </div>
</template>
<script>
import SongPitch from "@/components/SongPitch.vue"

export default({
    name: 'HomeView',
    components: {
        SongPitch
    }
})
</script>
